.table-striped tbody tr{
  background-color: #f8f8f8!important;
}
/* Dashboard */
.dashboard-vector-map {
    width: 100%;
    height: 300px;
}
.text-custum{
  color:$text-custum;
}
.fw-bold{
  font-weight:bold;
}
.text-heading{
  font-weight: 500;
  font-size: 13px;
}
.card-body.text-light.btn-outline-primary > div > .display-3{
  font-size: 26px !important;
  font-weight: 600;
}
.proBanner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    .purchase-popup {
      @extend .grid-margin;
      background: #000;
      color: #fff;
      // padding: 15px 20px;
      @include border-radius(3px);
      .btn {
        margin-right: 20px;
        font-weight: 500;
        color: $white;
        @include border-radius(5px);
        @include transition-duration(0.2s);
  
        &.download-button {
          background: rgba(249, 249, 249, 0.7);
          color: #969292;
          border: 1px solid darken(#e4e4e4,5%);
        }
  
        &.purchase-button {
          background-color: theme-color(info);
          color: $white;
          border: none;
          line-height: 1;
          vertical-align: middle;
        }
      }
  
      p {
        margin-bottom: auto;
        margin-top: auto;
        color: darken(#e4e4e4,20%);
        font-weight: 400;
        vertical-align: middle;
        line-height: 1;
      }
  
      i {
        vertical-align: middle;
        line-height: 1;
        margin: auto 0;
        color: darken(#e4e4e4,20%);
      }
      .bannerClose {
        cursor: pointer;
      }
    }
    &.hide {
      display: none;
    }
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0757a091;
    border-color: #d8ddf2;
    border-radius: 8px;
    .text-muted{
      color: #fff !important;
    }
}

.page-item.active .page-link {
  background-color: $text-custum;
  border-color: $text-custum;
}

.page-link{
  color: $text-custum;
}
// .card_after{
//   position:relative;
//   z-index:1;
//   overflow:hidden;
//   &:after{
//     content: "";
//     right: -48px;
//     height: 100px;
//     width: 100px;
//     background-color: #0757a03d;
//     position: absolute;
//     border-radius: 50%;
//     opacity: 0.3;
//  }
//   &:before{
//     content: "";
//     right: -33px;
//     height: 60px;
//     width: 60px;
//     background-color: rgb(7 87 160 / 94%);
//     position: absolute;
//     border-radius: 50%;
//     opacity: 0.3;
//     bottom: 7px;
//  }
// }
.card_after{
   position:relative;
   z-index:1;
   overflow:hidden;
   &:after{
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(210.04deg, #90caf9 -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: 0px;
    right: -147px;
  }
   &:before{
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, #90caf9 -14.02%, rgba(144, 202, 249, 0) 77.58%);
    border-radius: 50%;
    top: -121px;
    right: -130px;
  }
 }
 .text-heading{
  color:$text-custum;
 }
 .card_after:hover .card-body h4,
 .card_after:hover .card-body h3{
  color:#fff!important;
 } 
 .btn-outline:hover > *{
  color:#fff!important;
 }
.text-custum{
  color:$text-custum;
}
.margin-top-mobile{
  margin-top:15px;
}
@media(max-width:767px){
  .text-heading {
    font-weight: 400;
    font-size: 13px;
    height: auto;
}
.font-dashboad-heading{
   font-size:15px;
}
.mobile_top{
  margin-top:15px;
}
}